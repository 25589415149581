<template>
    <div id="app">
        <router-view/>
        <a id="button-up"></a>
    </div>
</template>

<script>
    import 'popper.js';
    import 'bootstrap/scss/bootstrap.scss';
    import 'bootstrap/dist/js/bootstrap.min.js';
    import 'devextreme/dist/css/dx.common.css';
    import '../assets/css/dx.generic.kel.css';
    import '@fortawesome/fontawesome-free/css/all.min.css';
    import '@fortawesome/fontawesome-free/js/all.min.js';
    import 'owl.carousel/dist/assets/owl.carousel.css';
    import 'owl.carousel';
    import '../main.scss';
    import ruMessages from "devextreme/localization/messages/ru.json";

    import {locale, loadMessages} from "devextreme/localization";

    let owl_carousel = require('owl.carousel');
    window.fn = owl_carousel;

    import jQuery from 'jquery';
    let $ = jQuery;

    export default {
        name: 'App',
        components: {},
        created() {
            loadMessages(ruMessages);
            locale('ru');

            $(document).ready(function () {
                var btn = $('#button-up');

                $(window).scroll(function() {
                    if ($(window).scrollTop() > 200) {
                        btn.addClass('show');
                    } else {
                        btn.removeClass('show');
                    }
                });

                btn.on('click', function(e) {
                    e.preventDefault();
                    $('html, body').animate({scrollTop:0}, '300');
                });
            });
        }
    }
</script>

<style lang="scss" scoped>
    #button-up {
        display: inline-block;
        background-color: #00afef;
        width: 65px;
        height: 65px;
        text-align: center;
        border-radius: 50px;
        /*margin: 30px;*/
        position: fixed;
        bottom: 100px;
        right: 60px;
        z-index: 1000;
        transition: background-color .3s, opacity .5s, visibility .5s;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
        text-decoration: none;
    }
    #button-up.show {
        opacity: 1;
        visibility: visible;
    }
    #button-up:hover {
        cursor: pointer;
        background-color: #ffcc29;
        text-decoration: none;
    }
    #button-up:active {
        background-color: #00afef;
    }
    #button-up::after {
        content: "\f077";
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-style: normal;
        font-size: 2em;
        line-height: 60px;
        color: #fff;
        text-decoration: none;
    }
    #button-up:hover::after {
        text-decoration: none;
    }

    @media (max-width: 769px) {
        #button-up {
            right: 20px;
        }
    }
</style>
