import axios from 'axios';

import {apiBaseUrl, apiKey} from '../../../../environment/environment';

/** Базовая конфигурация axios */
let config = {
    // Базовый URL-адрес сервера
    baseURL: apiBaseUrl
};

/** Создание экземпляра axios */
const httpClient = axios.create(config);

/** Добавление токена аутентификации, и обновление его, если это требуется */
const authInterceptor = config => {
    config.headers.ApiKey = apiKey;

    return config;
};

/** добавлени логгера при каждом axios запросе */
const loggerInterceptor = config => {
    /** как-то обрабатываем логи */
    return config;
};

httpClient.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

/** Добавляем экземпляру созданные обработчики для аутентификации и логов */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

httpClient.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) {
            if(window.router.currentRoute.name != 'ProfileChangePassword') {
                window.store.commit('setLoading', false);
                window.store.commit('clearAgent');
            }
        } else if (error.response.status === 403) {
            //this.$router.push({ name: '403' });
        }
        return Promise.reject(error)
    }
);

/** Добавление обработчика для результатов или ошибок при запросах */
httpClient.interceptors.response.use(
    response => {
        /** Как-то обрабатываем успешный результат */
        return response;
    },

    error => {
        /** Как-то обрабатываем результат, завершенный ошибкой */
        return Promise.reject(error);
    }
);

export {httpClient};
