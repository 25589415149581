import Vue from 'vue'
import Vuex from 'vuex'
import {httpClient} from '../../shared/services';
import moment from 'moment';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        cargos: null,
        currencys: null,
        route: null,
        offers: null
    },
    mutations: {
        setCurrencys(state, data) {
            state.currencys = data;
        },
        setCargos(state, data) {
            state.cargos = data;
        },
        setRoute(state, data) {
            state.route = data;
        },
        setOffers(state, data) {
            state.offers = data;
        }
    },
    actions: {
        getCurrencys({commit}) {
            commit('setCurrencys', null);
            return httpClient
                .get('v2/Catalog/Currencys')
                .then((response) => {
                    commit('setCurrencys', response.data.items);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getCargos({commit}, {order_field, order_desc, skeep, take, tokenId}) {
            commit('setCargos', null);
            let url = `v2/Marketplace/Cargos?order_field=${order_field}&order_desc=${order_desc}&skeep=${skeep}&take=${take}&groupCode=active`;

            if (tokenId) {
                url = url + `&tokenId=${tokenId}`;
            }

            url = url + `&filter={"and":[{"pickupDate":{"op":"gte","value":"${moment(moment().toDate()).format('YYYY-MM-DDT00:00:00')}"}}]}`;

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setCargos', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getRoute({commit}, {tokenId, cargoLotId}) {
            commit('setRoute', null);
            let url = `v2/Marketplace/Cargos/${cargoLotId}/Route`;

            if (tokenId) {
                url = url + `?tokenId=${tokenId}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setRoute', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        },
        getOffers({commit}, {tokenId, cargoLotId}) {
            commit('setOffers', null);
            let url = `v2/Marketplace/Cargos/${cargoLotId}/Offers`;

            if (tokenId) {
                url = url + `?tokenId=${tokenId}`;
            }

            return httpClient
                .get(url)
                .then((response) => {
                    commit('setOffers', response.data);
                }).catch(function (error) {
                    console.error(error);
                });
        }
    },
    getters: {},
    modules: {}
});
