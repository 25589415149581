export default {
    defaultsoutes: [
        {
            id: 1,
            name: 'Home',
            title: 'Новые грузы',
            hash: '#online-orders'
        },
        // {
        //     id: 2,
        //     name: 'Home',
        //     title: 'Автоперевозки',
        //     hash: '#auto-transportation'
        // },
        {
            id: 2,
            name: 'Home',
            title: 'КЕЛ в цифрах',
            hash: '#company-in-numbers'
        }
    ]
}


