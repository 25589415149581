<template>
    <div>
        <div class="footer">
            <div class="container">
                <div class="footer-nav">
                    <div class="row">
                        <div class="col-lg-6 d-none d-md-flex justify-content-between mb-3 mb-lg-0">
                            <div class="nav-col">
                                <div class="col-title">Полезное</div>
                                <ul>
                                    <li>
                                        <router-link :to="{ name: 'Marketplace' }" title="Тендер">Тендер</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'Partners' }" title="Партнеры">Партнеры</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'CareerCompany', hash: '#vacancies' }" title="Услуги">Вакансии</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'Login' }" title="Вход">Вход</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'Registration' }" title="Регистрация">Регистрация</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="nav-col">
                                <div class="col-title"><router-link :to="{ name: 'AboutCompany', hash: '#about-company' }" title="О компании">О компании</router-link></div>
                                <ul>
                                    <li>
                                        <router-link :to="{ name: 'AboutCompany', hash: '#company-in-numbers' }" title="Компания в цифрах">Компания в цифрах</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'AboutCompany', hash: '#services' }" title="Новости">Услуги</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'Contacts' }" title="Контакты">Контакты</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'AgreementsUser' }" title="Пользовательское соглашение">Пользовательское соглашение</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{ name: 'AgreementsPrivacy' }" title="Политика конфиденциальности">Политика конфиденциальности</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="nav-col">
<!--                                <div class="col-title">Для удобства</div>-->
<!--                                <ul>-->
<!--                                    <li>-->
<!--                                        <router-link :to="{ name: 'Marketplace' }" title="Ищу груз">Ищу груз</router-link>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <router-link :to="{ name: 'Dev' }" title="Ищу транспорт">Ищу транспорт</router-link>-->
<!--                                    </li>-->
<!--                                    <li>-->
<!--                                        <router-link :to="{ name: 'Dev' }" title="Отслеживание груза">Отслеживание груза</router-link>-->
<!--                                    </li>-->
<!--                                </ul>-->
                            </div>
                        </div>
                        <div class="col-lg-6 d-md-flex">
                            <div class="flex-fill">
                                <address><span class="d-block mb-2">Республика Казахстан,</span><span class="d-block mb-2">г. Алматы,</span><span class="d-block">ул. Майлина 85/2</span></address>
                            </div>
                            <div class="flex-fill">
                                <a href="tel:+77273122110" class="phone d-block mb-2">+7 (727) 312-21-10</a>
                                <a href="tel:+77717055541" class="phone d-block mb-2">+7 (771) 705-55-41</a>
                                <a href="mailto:info@kel.kz" class="mail link-ul">info@kel.kz</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <div class="row">
                        <div class="col-12 d-flex align-items-center">
                            <div>
                                <img src="@/assets/images/logo-notext.svg">
                            </div>
                            <div class="flex-fill text-right">
                                © 2021 KEL
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-tricolor d-flex">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .footer {
        color: #fff;
        position: relative;
        z-index: 1;
        background-color: var(--color-primary);
    }

    .footer a {
        color: #fff;
    }

    .footer-nav {
        padding-top: 50px;
        padding-bottom: 25px;
        position: relative;
        z-index: 5;

        .nav-col .col-title {
            margin-bottom: .75rem;
            font-size: var(--size-20);
        }

        .nav-col ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        .nav-col ul li {
            margin-bottom: .5rem;
        }

        .nav-col ul li a {
            font-weight: 300;
        }
    }

    .footer-nav address {
        font-size: var(--size-22);
        color: #FFCC29;
    }

    .footer-nav .phone {
        font-size: var(--size-22);
        color: #FFCC29;
    }

    .footer-nav .mail {
        font-size: var(--size-22);
        font-weight: 300;
        color: #FFCC29;
    }

    .footer-bottom {
        padding: 30px 0;
        font-weight: 300;
        font-size: var(--size-14);
        border-top: 1px solid rgba(255, 255, 255, .25);
    }

    .footer-tricolor {
        > div {
            height: 8px;
            width: 33.33333333%;
        }

        > div:nth-child(1) {
            background: #214b8d;
        }

        > div:nth-child(2) {
            background: var(--color-yellow);
        }

        > div:nth-child(3) {
            background-color: var(--color-secondary);
        }
    }
</style>
