<template>
    <div class="header fixed-top">
        <div class="container d-flex">
            <button class="navbar-toggler d-lg-none collapsed" type="button" data-toggle="collapse"
                    data-target="#mobile-navbar" aria-controls="mobile-navbar" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="d-flex mr-4">
                <img src="@/assets/images/logo.svg" class="logo" @click="openHome">
            </div>
            <div class="d-flex d-lg-none flex-column justify-content-between ml-auto mobile-right">
                <div class="phone">
                    <a href="tel:+77273122110" class="phone d-block mb-2">
                        <img src="@/assets/images/ico-phone.svg"> +7 (727) 312-21-10 </a>
                </div>
                <div v-if="!isCabinetDisabled && !isLogged" class="btn-group btn-group-sm">
                    <div class="btn-group btn-group-sm" role="group">
                        <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="openLogin">
                            <i class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                    <button type="button" class="btn btn-secondary clip" title="Вход/Регистрация" @click="openLogin">
                        Вход/Регистрация
                    </button>
                </div>
                <div v-if="!isCabinetDisabled && isLogged" class="btn-group btn-group-sm">
                    <button type="button" class="btn btn-secondary clip dropdown-toggle" data-toggle="dropdown"
                            data-display="static" aria-haspopup="true" aria-expanded="false" :title="Contact ? Contact.contactName : Agent.logIn.substr(0, Agent.logIn.indexOf(':'))">
                        <i class="fas fa-chevron-down mr-2"></i>{{ Contact ? Contact.contactName : Agent.logIn.substr(0, Agent.logIn.indexOf(':')) }}
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left">
                        <router-link :to="{ name: 'ProfileCounterparty' }" class="dropdown-item" :class="{ 'active': $route.name == 'ProfileCounterparty'}" title="Профиль">
                            <i class="fas fa-user mr-3"></i>Профиль
                        </router-link>
                        <router-link :to="{ name: 'ProfileChangePassword' }" class="dropdown-item" :class="{ 'active': $route.name == 'ProfileChangePassword'}" title="Сменить пароль">
                            <i class="fas fa-key mr-3"></i>Сменить пароль
                        </router-link>
                        <div class="dropdown-divider" v-if="isDisplayRouterLink('ПР')"></div>
                        <!--                            <h6 class="dropdown-header">Справочники</h6>-->
                        <router-link :to="{ name: 'CarrierCars' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierCars'}" title="Транспорт" v-if="isDisplayRouterLink('ПР')">
                            Транспорт
                        </router-link>
                        <router-link :to="{ name: 'CarrierDrivers' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierDrivers'}" title="Водители" v-if="isDisplayRouterLink('ПР')">
                            Водители
                        </router-link>
                        <div class="dropdown-divider" v-if="isDisplayRouterLink('ПР')"></div>
                        <router-link :to="{ name: 'CarrierRollingStocks' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierRollingStocks'}" title="Перевозки" v-if="isDisplayRouterLink('ПР')">
                            Перевозки
                        </router-link>
                        <router-link :to="{ name: 'CarrierOffers' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierOffers'}" title="Ценовые предложения" v-if="isDisplayRouterLink('ПР')">
                            Предложения
                        </router-link>
                        <div class="dropdown-divider" v-if="isDisplayRouterLink('СА') || isDisplayRouterLink('АСРВ')"></div>
                        <router-link :to="{ name: 'Dev' }" class="dropdown-item" title="Страховки" v-if="isDisplayRouterLink('СА')">
                            Страховки
                        </router-link>
                        <router-link :to="{ name: 'Dev' }" class="dropdown-item" title="Чек листы" v-if="isDisplayRouterLink('АСРВ')">
                            Чек листы
                        </router-link>
                        <div class="dropdown-divider" v-if="isDisplayRouterLink('ПР')"></div>
                        <router-link :to="{ name: 'PrimaryDocsContractAppCarriers' }" class="dropdown-item" :class="{ 'active': $route.name == 'PrimaryDocsContractAppCarriers'}" title="Заявки на перевозку" v-if="isDisplayRouterLink('ПР')">
                            ЗПп
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <router-link :to="{ name: 'FinancesInvoices' }" class="dropdown-item" :class="{ 'active': $route.name == 'FinancesInvoices'}" title="Счета">
                            Счета
                        </router-link>
                        <router-link :to="{ name: 'FinancesPayings' }" class="dropdown-item" :class="{ 'active': $route.name == 'FinancesPayings'}" title="Оплаты">
                            Оплаты
                        </router-link>
                        <router-link :to="{ name: 'FinancesElementsService' }" class="dropdown-item" :class="{ 'active': $route.name == 'FinancesElementsService'}" title="Услуги">
                            НВУ
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <button class="dropdown-item text-danger" type="button" @click="logOut"><i class="fas fa-sign-out-alt mr-3"></i>Выход</button>
                    </div>
                </div>
            </div>
            <div class="d-none d-lg-flex flex-column justify-content-between flex-fill ml-4">
                <div class="header-half-top d-flex align-items-start">
                    <nav class="navbar navbar-desktop mr-auto">
                        <ul class="navbar-nav flex-row">
                            <li class="nav-item">
                                <router-link :to="{ name: 'AboutCompany'}" class="nav-link" title="О компании">О компании</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'Partners'}" class="nav-link" title="Партнеры">Партнеры</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'Marketplace'}" class="nav-link" title="Тендер">Тендер</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'CareerCompany'}" class="nav-link" title="Карьера">Карьера</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link :to="{ name: 'Contacts'}" class="nav-link" title="Контакты">Контакты</router-link>
                            </li>
                        </ul>
                    </nav>
                    <div class="phone">
                        <a href="tel:+77273122110" class="phone d-block mb-2">
                            <img src="@/assets/images/ico-phone.svg"> +7 (727) 312-21-10
                        </a>
                    </div>
                </div>
                <div class="header-half-bottom d-flex">
                    <nav class="navbar navbar-desktop mr-auto">
                        <ul class="navbar-nav flex-row">
                            <li class="nav-item" v-for="route in routes" :key="route.id">
                                <router-link :to="{ name: route.name, hash: route.hash ? route.hash : '' }"
                                             class="nav-link" :title="route.title">{{ route.title }}
                                </router-link>
                            </li>
                        </ul>
                    </nav>
                    <div v-if="!isCabinetDisabled && !isLogged" class="btn-group btn-group-sm">
                        <div class="btn-group btn-group-sm" role="group">
                            <button type="button" class="btn btn-primary" @click="openLogin">
                                <i class="fas fa-chevron-right"></i>
                            </button>
                        </div>
                        <button type="button" class="btn btn-secondary clip" title="Вход/Регистрация"
                                @click="openLogin">
                            Вход/Регистрация
                        </button>
                    </div>
                    <div v-if="!isCabinetDisabled && isLogged" class="btn-group btn-group-sm dropdown-agent-menu">
                        <button type="button" class="btn btn-secondary clip dropdown-toggle" data-toggle="dropdown"
                                data-display="static" aria-haspopup="true" aria-expanded="false"
                                :title="Contact ? Contact.contactName : Agent.logIn.substr(0, Agent.logIn.indexOf(':'))">
                            <i class="fas fa-chevron-down mr-2"></i>{{ Contact ? Contact.contactName : Agent.logIn.substr(0, Agent.logIn.indexOf(':')) }}
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                            <router-link :to="{ name: 'ProfileCounterparty' }" class="dropdown-item" :class="{ 'active': $route.name == 'ProfileCounterparty'}" title="Профиль">
                                <i class="fas fa-user mr-3"></i>Профиль
                            </router-link>
                            <router-link :to="{ name: 'ProfileChangePassword' }" class="dropdown-item" :class="{ 'active': $route.name == 'ProfileChangePassword'}" title="Сменить пароль">
                                <i class="fas fa-key mr-3"></i>Сменить пароль
                            </router-link>
                            <div class="dropdown-divider" v-if="isDisplayRouterLink('ПР')"></div>
<!--                            <h6 class="dropdown-header">Справочники</h6>-->
                            <router-link :to="{ name: 'CarrierCars' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierCars'}" title="Транспорт" v-if="isDisplayRouterLink('ПР')">
                                Транспорт
                            </router-link>
                            <router-link :to="{ name: 'CarrierDrivers' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierDrivers'}" title="Водители" v-if="isDisplayRouterLink('ПР')">
                                Водители
                            </router-link>
                            <div class="dropdown-divider" v-if="isDisplayRouterLink('ПР')"></div>
                            <router-link :to="{ name: 'CarrierRollingStocks' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierRollingStocks'}" title="Перевозки" v-if="isDisplayRouterLink('ПР')">
                                Перевозки
                            </router-link>
                            <router-link :to="{ name: 'CarrierOffers' }" class="dropdown-item" :class="{ 'active': $route.name == 'CarrierOffers'}" title="Ценовые предложения" v-if="isDisplayRouterLink('ПР')">
                                Предложения
                            </router-link>
                            <div class="dropdown-divider" v-if="isDisplayRouterLink('СА') || isDisplayRouterLink('АСРВ')"></div>
                            <router-link :to="{ name: 'Dev' }" class="dropdown-item" title="Страховки" v-if="isDisplayRouterLink('СА')">
                                Страховки
                            </router-link>
                            <router-link :to="{ name: 'Dev' }" class="dropdown-item" title="Чек листы" v-if="isDisplayRouterLink('АСРВ')">
                                Чек листы
                            </router-link>
                            <div class="dropdown-divider" v-if="isDisplayRouterLink('ПР')"></div>
                            <router-link :to="{ name: 'PrimaryDocsContractAppCarriers' }" class="dropdown-item" :class="{ 'active': $route.name == 'PrimaryDocsContractAppCarriers'}" title="Заявки на перевозку" v-if="isDisplayRouterLink('ПР')">
                                ЗПп
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <router-link :to="{ name: 'FinancesInvoices' }" class="dropdown-item" :class="{ 'active': $route.name == 'FinancesInvoices'}" title="Счета">
                                Счета
                            </router-link>
                            <router-link :to="{ name: 'FinancesPayings' }" class="dropdown-item" :class="{ 'active': $route.name == 'FinancesPayings'}" title="Оплаты">
                                Оплаты
                            </router-link>
                            <router-link :to="{ name: 'FinancesElementsService' }" class="dropdown-item" :class="{ 'active': $route.name == 'FinancesElementsService'}" title="Услуги">
                                НВУ
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <button class="dropdown-item text-danger" type="button" @click="logOut"><i class="fas fa-sign-out-alt mr-3"></i>Выход</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-navbar navbar-collapse collapse" id="mobile-navbar" style="">
            <div class="container">
                <ul class="navbar-nav">
                    <li class="nav-item global" v-for="route in routes" :key="route.id">
                        <router-link :to="{ name: route.name, hash: route.hash ? route.hash : '' }" class="nav-link"
                                     :title="route.title">{{ route.title }}
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Home'}" class="nav-link" title="Главная">Главная</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'AboutCompany'}" class="nav-link" title="О компании">О компании</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Partners'}" class="nav-link" title="Партнеры">Партнеры</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Marketplace'}" class="nav-link" title="Тендер">Тендер</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'CareerCompany'}" class="nav-link" title="Карьера">Карьера</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link :to="{ name: 'Contacts'}" class="nav-link" title="Контакты">Контакты</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { isCabinetDisabled } from '../../../../environment/environment';
    import jQuery from 'jquery';
    let $ = jQuery;
    import {mapGetters} from 'vuex';

    export default {
        name: 'Header',
        props: ['routes'],
        data() {
            return {
                isCabinetDisabled
            }
        },
        mounted() {
            $('.dropdown-agent-menu').hover(function(){
                //$(this).children('[data-toggle="dropdown"]').click();
            }, function(){
                //$(this).children('[data-toggle="dropdown"]').click();
            });
        },
        methods: {
            openHome() {
                if (this.$route.path !== '/') {
                    this.$router.push('/');
                }
            },
            openLogin() {
                if (this.$route.path !== '/login') {
                    this.$router.push({name: 'Login', query: {redirect: this.$route.path}});
                }
            },
            logOut() {
                this.$store.dispatch('logOut', this.Agent);
            },
            isDisplayRouterLink(clsfKind) {
                if(this.clsfCards) {
                    let objIndex = this.clsfCards.findIndex((obj => obj.clsfKind == clsfKind));
                    if(objIndex != -1) {
                        return this.clsfCards[objIndex].isActive;
                    }
                }

                return false;
            }
        },
        computed: {
            ...mapGetters([
                'isLogged',
                'Agent',
                'Contact',
                'clsfCards',
            ])
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .header {
        padding: 1rem 0;
        background-color: #fff;
        box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);

        .logo {
            width: 80px;

            @media (min-width: 576px) {
                width: auto;
            }
        }

        .phone {
            color: #000;
        }

        .header-title {
            padding-bottom: 0;
            font-size: var(--size-22);
            color: #000;
        }
    }

    .navbar-desktop {
        padding: 0;

        .nav-link {
            padding-top: 0;
            color: #777;
            position: relative;
            transition: var(--default-transition);
        }

        .nav-link:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0px;
            width: 100%;
            height: 4px;
            border-radius: 2px 2px 0px 0px;
        }
    }

    .header-half-top {
        margin-bottom: .75rem;
        border-bottom: 1px solid #d8d8d8;

        .navbar-desktop {

            .nav-item + .nav-item {
                margin-left: 2rem;
            }

            .nav-link:hover:after,
            .nav-link.active:after {
                background: var(--color-primary);
            }

            .nav-link:hover {
                color: var(--color-primary);
            }
        }
    }

    .mobile-right {
        .dropdown-toggle::after {
            display: none;
        }
    }

    .header-half-bottom {
        .dropdown-toggle::after {
            display: none;
        }

        .nav-link {
            padding-bottom: 0;
            font-size: var(--size-22);
            color: #000;
        }

        h2 {
            font-size: var(--size-22);
            line-height: 1.4;
        }

        .nav-item:hover {
            .nav-link:after {
                background: var(--color-primary);
            }
        }

        /*.dropdown-agent-menu:hover>.dropdown-menu {*/
        /*    display: block;*/
        /*}*/

        /*.nav-item:nth-child(1) {*/
        /*  .nav-link:after {*/
        /*    background: var(--color-primary);*/
        /*  }*/
        /*}*/

        /*.nav-item:nth-child(2) {*/
        /*  .nav-link:after {*/
        /*    background: var(--color-yellow);*/
        /*  }*/
        /*}*/

        /*.nav-item:nth-child(3) {*/
        /*  .nav-link:after {*/
        /*    background: var(--color-secondary);*/
        /*  }*/
        /*}*/

        .nav-link:after {
            bottom: -16px;
        }

        .nav-item + .nav-item {
            margin-left: 3rem;
        }
    }

    .navbar-toggler:active, .navbar-toggler:focus {
        outline: none;
        border: 0;
    }

    .navbar-toggler {
        padding: .25rem 1rem;
        margin: -1rem 1rem -1rem -15px;
        border-radius: 0;
        background-color: var(--color-primary);
        outline: none;
        border: 0;
    }

    @media (min-width: 576px) {
        .navbar-toggler {
            padding: .25rem 1.25rem;
        }
    }

    .navbar-toggler-icon {
        width: 34px;
        height: 3px;
        position: relative;
        background-color: #fff;
    }

    .navbar-toggler-icon:before {
        top: 12px;
    }

    .navbar-toggler-icon:before, .navbar-toggler-icon:after {
        content: '';
        position: absolute;
        left: 0;
        width: 34px;
        height: 3px;
        background-color: #fff;
    }

    .navbar-toggler-icon:after {
        bottom: 12px;
    }

    .navbar-toggler-icon:before, .navbar-toggler-icon:after {
        content: '';
        position: absolute;
        left: 0;
        width: 34px;
        height: 3px;
        background-color: #fff;
    }

    .mobile-navbar {
        padding-top: 30px;
    }

    .mobile-navbar .nav-item {
        border-bottom: 1px solid rgba(216, 216, 216, .5);
    }

    .mobile-navbar .nav-item.global, .mobile-navbar .nav-item.delivery, .mobile-navbar .nav-item.moving {
        border-bottom: 0;
    }

    .mobile-navbar .nav-link {
        padding: 0.75rem 15px;
        font-size: 18px;
        color: #777;
    }

    .mobile-navbar .nav-item.global > .nav-link {
        margin: 0 -15px;
        /*border-bottom: 5px solid var(--color-primary);*/
    }

    .mobile-navbar .nav-item.global > .nav-link, .mobile-navbar .nav-item.delivery > .nav-link, .mobile-navbar .nav-item.moving > .nav-link {
        font-size: 22px;
        color: #000;
    }

    .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
        background-color: transparent;
    }

    .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before {
        transform: rotate(
                        45deg
        ) translate(-9px, -9px);
        transform-origin: center;
    }

    .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after {
        transform: rotate(
                        -45deg
        ) translate(-8px, 8px);
        transform-origin: center;
    }
</style>
