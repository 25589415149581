import Vue from 'vue';
import VueRouter from 'vue-router';

import jQuery from 'jquery';
let $ = jQuery;

import { isCabinetDisabled } from '../../environment/environment';

import Home from '../components/home/Home';

Vue.use(VueRouter);

const cabinetRoutes = isCabinetDisabled ? [] : [
    { // auth - start
        name: 'Login',
        path: '/login',
        component: () => import('../components/auth/login/Login.vue'),
    },
    {
        name: 'Registration',
        path: '/registration',
        component: () => import('../components/auth/registration/Registration.vue'),
    },
    {
        name: 'ResetPassword',
        path: '/reset-password',
        component: () => import('../components/auth/reset-password/ResetPassword.vue'),
    },
    {
        name: 'Verified',
        path: '/verified',
        component: () => import('../components/auth/verified/Verified.vue'),
    }, // auth - end
    // profile - start
    // {
    //     name: 'ProfileAgent',
    //     path: '/profile/agent',
    //     component: () => import('../components/profile/Agent.vue'),
    //     meta: {
    //         requiresAuth: true
    //     },
    // },
    {
        name: 'ProfileCounterparty',
        path: '/profile/counterparty',
        component: () => import('../components/profile/Counterparty.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'Profile',
        path: '/profile',
        redirect: { name: 'ProfileCounterparty' }
    },
    { path: '/profile/', redirect: { name: 'ProfileCounterparty' }},
    {
        name: 'ProfileChangePassword',
        path: '/profile/change-password',
        component: () => import('../components/profile/ChangePassword.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'ProfileContacts',
        path: '/profile/contacts',
        component: () => import('../components/profile/Contacts.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'ProfileContracts',
        path: '/profile/contracts',
        component: () => import('../components/profile/Contracts.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'ProfileContract',
        path: '/profile/contracts/:contractId',
        component: () => import('../components/profile/Contract.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'ProfileNotifications',
        path: '/profile/notifications',
        component: () => import('../components/profile/Notifications.vue'),
        meta: {
            requiresAuth: true
        },
    },// profile - end
    { // carrier - start
        name: 'CarrierCars',
        path: '/carrier/cars',
        component: () => import('../components/carrier/Cars.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'CarrierCarAdd',
        path: '/carrier/cars/add/:motor',
        component: () => import('../components/carrier/CarAdd.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'CarrierCarEdit',
        path: '/carrier/cars/:carId',
        component: () => import('../components/carrier/CarEdit.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'CarrierDrivers',
        path: '/carrier/drivers',
        component: () => import('../components/carrier/Drivers.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'CarrierDriverAdd',
        path: '/carrier/drivers/add',
        component: () => import('../components/carrier/DriverAdd.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'CarrierDriverEdit',
        path: '/carrier/drivers/:driverId',
        component: () => import('../components/carrier/DriverEdit.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'CarrierOffers',
        path: '/carrier/offers',
        component: () => import('../components/carrier/Offers.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'CarrierRollingStocks',
        path: '/carrier/rolling-stocks',
        component: () => import('../components/carrier/RollingStocks.vue'),
        meta: {
            requiresAuth: true
        },
    },// carrier - end
    { // finances - start
        name: 'FinancesInvoices',
        path: '/finances/invoices',
        component: () => import('../components/finances/Invoices.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'Finances',
        path: '/finances',
        redirect: { name: 'FinancesInvoices' }
    },
    { path: '/finances/', redirect: { name: 'FinancesInvoices' }},
    {
        name: 'FinancesInvoice',
        path: '/finances/invoices/:invoiceId',
        component: () => import('../components/finances/Invoice.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'FinancesPayings',
        path: '/finances/payings',
        component: () => import('../components/finances/Payings.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'FinancesPaying',
        path: '/finances/payings/:payingId',
        component: () => import('../components/finances/Paying.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'FinancesElementsService',
        path: '/finances/elements-service',
        component: () => import('../components/finances/ElementsService.vue'),
        meta: {
            requiresAuth: true
        },
    }, // finances - end
    { // /primary-docs - start
        name: 'PrimaryDocsPrimaryDocument',
        path: '/primary-docs/primary-document/:primaryDocumentId',
        component: () => import('../components/primary-docs/PrimaryDocument.vue'),
        meta: {
            requiresAuth: true
        },
    },
    {
        name: 'PrimaryDocsContractAppCarriers',
        path: '/primary-docs/contract-app-carriers',
        component: () => import('../components/primary-docs/ContractAppCarriers.vue'),
        meta: {
            requiresAuth: true
        },
    }, // /primary-docs - end
    { // /agreements - start
        name: 'AgreementsUser',
        path: '/agreements/user',
        component: () => import('../components/agreements/AgreementsUser.vue'),
    },
    {
        name: 'AgreementsPrivacy',
        path: '/agreements/privacy',
        component: () => import('../components/agreements/AgreementsPrivacy.vue'),
    }, // /agreements - end
];

const routes = [
    {
        name: 'Home',
        path: '/',
        alias: ['/home', '/home/'],
        component: Home,
    },
    {
        name: 'Partners',
        path: '/partners',
        component: () => import('../components/partners/Partners.vue'),
    },
    {
        name: 'Contacts',
        path: '/contacts',
        component: () => import('../components/contacts/Contacts.vue'),
    },
    {
        name: 'AboutCompany',
        path: '/about-company',
        component: () => import('../components/about-company/AboutCompany.vue'),
    },
    {
        name: 'CareerCompany',
        path: '/career-company',
        component: () => import('../components/career-company/CareerCompany.vue'),
    },
    { // marketplace - start
        name: 'Marketplace',
        path: '/marketplace',
        redirect: {name: 'Cargos'},
    },
    {
        name: 'CargosLotId',
        path: '/marketplace/cargos/:cargoLotId',
        component: () => import('../components/marketplace/Cargos.vue'),
    },
    {
        name: 'Cargos',
        path: '/marketplace/cargos',
        component: () => import('../components/marketplace/Cargos.vue'),
    }, // marketplace - end
    ...cabinetRoutes,
    { // errors - start
        name: 'Dev',
        path: '/dev',
        component: () => import('../components/errors/ErrorDev.vue'),
    },
    {
        name: 'Error404',
        path: '*',
        component: () => import('../components/errors/Error404.vue'),
    } // errors - end
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to) { // , from, savedPosition
        if (to.hash && $(to.hash).position() != undefined) {
            let top = Math.ceil($(to.hash).position().top);
            let offset = 80;

            switch (to.hash) {
                case '#about-company':
                case '#career':
                    top = 0;
                    offset = 0;
                    break;
                case '#vacancies':
                    offset = 125;
                    break;
                case '#online-orders':
                    offset = 105;
                    break;
            }

            console.log(top, offset);

            $('html, body').animate({scrollTop: top - offset}, '300');
            return { x: 0, y: (top-offset) }
        } else {
            return { x: 0, y: 0 }
        }

        // if (to.hash) {
        //     return { x: 0, y: $(to.hash).position().top }//{ selector: to.hash, offset: { x: 0, y: 150 } }
        // } else if (savedPosition) {
        //     return savedPosition;
        // } else {
        //     return { x: 0, y: 0 }
        // }
    }
});

router.beforeEach((to, from, next) => {
    let agentInfo = localStorage.getItem('agent');

    // if(agentInfo) {
    //     const agentData = JSON.parse(agentInfo);
    //     let limitedTime = new Date(agentData.limitedTime);
    //     let nowTime = new Date();
    //     if(nowTime > limitedTime) {
    //         window.store.commit('clearAgent');
    //     }
    // }

    if (to.matched.some(record => record.meta.requiresAuth) && !agentInfo) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    }

    if (to.matched.some(record => record.name === 'Verified') && !agentInfo) {
        next('/');
    }

    if (to.matched.some(record => record.name != 'Verified') && agentInfo) {
        const agentData = JSON.parse(agentInfo);

        if (!agentData.isVerified) {
            next({
                path: '/verified',
                query: { redirect: to.fullPath }
            });
        }
    }

    if (to.matched.some(record => record.name === 'Login') && agentInfo) {
        next('/');
    }

    if (to.matched.some(record => record.name === 'Registration') && agentInfo) {
        next('/');
    }

    if (to.matched.some(record => record.name === 'ResetPassword') && agentInfo) {
        next('/');
    }

    next();
});

export default router;
