import Vue from 'vue';

window.Vue = Vue;
import $ from 'jquery'

window.$ = $;
window.jQuery = $;

import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './app/app-store'
import router from './app/router'
import App from './app/App.vue';
import VueMq from 'vue-mq'

import Title from './app/shared/components/header/Title';

Vue.component('vue-title', Title);

import Paginate from 'vuejs-paginate';

Vue.component('paginate', Paginate);

window.store = store;
window.router = router;

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(require('vue-moment'));

Vue.use(VueMq, {
    breakpoints: { // default breakpoints - customize this
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        fluid: Infinity,
    },
    defaultBreakpoint: 'sm' // customize this for SSR
});

Vue.filter('formatMoney', function (value) {
    if (value === 0) return 0;
    if (!value) return '';
    return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").replace('.', ',').replace(',00', '');
});

Vue.filter('formatMoney2', function (value) {
    if (value === '-') return value;
    if (value === 0) return '0,00';
    if (!value) return '';
    return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").replace('.', ',');
});

Vue.filter('formatPhone', function (value) {
    if (!value) return '-';

    let phone = value;

    if(phone.length == 11) {
        if(phone.substring(0, 1) == '8') {
            phone = '7' + phone.substring(1, phone.length);
        }

        phone = '+' + phone.substring(0, 1) + ' (' + phone.substring(1, 4) + ') ' + phone.substring(4, 7) + ' ' + phone.substring(7, 9) + ' ' + phone.substring(9, 11)
    }

    return phone;
});

Vue.filter('encodeURL', function (value) {
    return encodeURIComponent(value).replace(/[!'()*]/g, function(c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
});

Vue.filter('clearPhone', function (value) {
    if (!value) return '';

    let phone = value.replace(/[!'()*-+]/g, '');
    phone = phone.replace(/\s/g, '');

    return phone;
});

Vue.filter('changeStartEightInPhone', function (value) {
    if (!value) return '';

    let phone = value.replace(/[!'()*-+]/g, '');
    phone = phone.replace(/\s/g, '');

    if(phone.length == 11) {
        if(phone.substring(0, 1) == '8') {
            phone = '7' + phone.substring(1, phone.length);
        }
    }

    return phone;
});

new Vue({
    router,
    store,
    created() {
        const agentInfo = localStorage.getItem('agent');
        if (agentInfo) {
            const agentData = JSON.parse(agentInfo);
            this.$store.commit('setAgent', agentData);
        }

        const contactInfo = localStorage.getItem('contact');
        if (contactInfo) {
            const contactData = JSON.parse(contactInfo);
            this.$store.commit('setContact', contactData);
        }

        const clsfCards = localStorage.getItem('clsfCards');
        if (clsfCards) {
            const clsfCardsData = JSON.parse(clsfCards);
            this.$store.commit('setClsfCards', clsfCardsData);
        }
    },
    render: h => h(App),
}).$mount('#app');
